import "./Advantages.css";
// import greyBg from '../../../../assets/images/grayBg.png'

function Advantages({ }) {

    const PROMO = [
        {
            title: 'Собственное</br>производство',
            img: <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <rect width="60" height="60" fill="white" />
                <rect x="29" y="12" width="25" height="36" fill="#E1E7F8" />
                <path d="M30.2004 17L20 31.0556H29L27.7996 40L38 25.9444H29L30.2004 17Z" fill="#0D1738" />
                <rect x="6" y="12" width="48" height="36" stroke="#0D1738" stroke-width="4" stroke-miterlimit="10" />
                <rect x="12" y="6" width="10" height="6" stroke="#0D1738" stroke-width="4" stroke-miterlimit="10" />
                <rect x="38" y="6" width="10" height="6" stroke="#0D1738" stroke-width="4" stroke-miterlimit="10" />
                <path d="M9 54H51" stroke="#0D1738" stroke-width="4" stroke-miterlimit="10" />
            </svg>,
            subtext: `Широкий модельный ряд.</br>
            Высокие характеристики</br>
            и техподдержка.`
        },
        {
            title: 'Высокое</br>качество',
            img: <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path d="M47.5 32.5V25H53.5V34.5L46.6733 49.3582C45.6428 51.6009 43.4633 53.096 41 53.25L17 53.5V47H37.5656C39.0609 47 40.4314 46.1659 41.1184 44.8377L47.5 32.5Z" fill="#E1E7F8" />
                <rect x="6" y="25" width="11" height="28" fill="#E1E7F8" />
                <path d="M6 24.42V53H40.3129C42.9823 53 45.4118 51.44 46.5216 49L54 32.52V24.42H31.4847L34.1041 8.48L32.3545 7L17.3776 24.42H6Z" stroke="#0D1738" stroke-width="4" stroke-miterlimit="10" />
                <path d="M17.3678 24.42V53" stroke="#0D1738" stroke-width="4" stroke-miterlimit="10" />
                <circle cx="12" cy="47" r="2" fill="#0D1738" />
            </svg>,
            subtext: `Мы следим за качеством</br>
            нашей продукции, потому</br>
            что дорожим своим брендом.`
        },
        {
            title: 'Низкие</br>цены',
            img: <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8125 51.1055C19.0851 54.1855 24.3307 56 30 56C44.3594 56 56 44.3594 56 30C56 21.365 51.7905 13.7131 45.3116 8.98462L14.8125 51.1055Z" fill="#E1E7F8" />
                <circle cx="30" cy="30" r="24" stroke="#0D1738" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <circle cx="30" cy="30" r="19" stroke="#0D1738" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.6006 15L18 33.3333H30L28.3994 45L42 26.6667H30L31.6006 15Z" fill="#0D1738" />
            </svg>,
            full: true,
            subtext: `Работаем без посредников.</br>
            Мы стремимся предложить вам</br>
            лучшие условия и доступные цены.`
        },
    ]

    return (
        <div className="advantages">
            {/* <img className="advantages__bg" src={greyBg}></img> */}
            <div className="advantages__cards-box">
                {PROMO.map((item, i) => {
                    return (
                        <>
                            <div className="advantages__card">
                                <p className="advantages__card__number">0{i + 1}</p>
                                <div className="advantages__card__img">{item.img}</div>
                                <div>
                                    <p className="advantages__card__title" dangerouslySetInnerHTML={{ __html: item.title }} />
                                    <p className="advantages__card__subtext" dangerouslySetInnerHTML={{ __html: item.subtext }} />
                                </div>
                            </div>
                            {i + 1 < PROMO.length ? <div className="advantages__card__separator"></div> : null}
                        </>
                    )
                })}
            </div>
        </div>
    );
}

export default Advantages;