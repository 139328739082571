import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import "./ProductPage.css";
// import ProductRecs from './ProductRecs/ProductRecs';
import { useContext, useEffect, useRef, useState } from "react";
import mainApi from "../../assets/api/MainApi";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import NotFoundBlock from "../NotFoundBlock/NotFoundBlock";
import {
  buildCategoryPath,
  compareOptions,
  getCorrectWordForm,
  getFinalPrice,
  parseParameterValue,
  parsePrice,
  parseTitleForParts,
} from "../../assets/utils/utils";
import { CART_MAIN_LINK, CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT, MAIN_URL } from "../../assets/utils/constants";
import { CrossIcon } from "../../assets/icons/icons";
import { ConfigContext } from "../../assets/contexts/configContext";
import { CartContext } from "../../assets/contexts/cartContext";
import { ContactContext } from "../../assets/contexts/contactContext";

function ProductPage({
  // handleAddToCart,
  // cart,
  // handleRemoveFromCart,
  item_id,
  levels,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const cart_context = useContext(CartContext)
  const {
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    // setCart,
  } = cart_context

  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { CONSTANTS } = initialConstants
  const { SHOW_AMOUNT_IN_STOCK_IN_GOODS_PAGE = true, CONTACT_US_CART_BTN = "Хочу также!" } = CONSTANTS
  const { SHOP_ID_FOR_API, SHOP_NAME } = initialConstants

  const [preloaders, setPreloaders] = useState({
    item: true,
  });
  const [product, setProduct] = useState(undefined);
  const [selectedOptions, setSelectedOptions] = useState(null)
  const [promotedProducts, setPromotedProducts] = useState(undefined);
  const removeCartRef = useRef(null);

  // const [fullScreenSrc, setFullScreenSrc] = useState(undefined)

  useEffect(() => {
    if (!item_id || !levels) return;

    console.log({ levels, item_id });
    // const pathname = location.pathname.split("/");
    // const id = pathname[pathname.length - 1];
    setPreloaders((prevState) => ({
      ...prevState,
      item: true,
    }));
    mainApi
      .getExactProduct({
        shop_id: SHOP_ID_FOR_API,
        _id: item_id,
      })
      .then((res) => {
        console.log(res);
        setProduct(res);
        const options = res.options
        if (options && options.length > 0) {

          let initial_options = searchParams.get("options")
          if (!initial_options) {
            setSelectedOptions(options.map((option) => {
              return {
                name: option.name,
                value: option.values[0].value,
                in_stock: option.values[0].in_stock,
                price: option.values[0].price,
              }
            }))
          } else {
            setSelectedOptions(JSON.parse(initial_options))
          }
        }
        getPromotedProducts({
          category: res.categories?.length > 0 ? res.categories[0]._id : null,
        });
        window.dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            detail: {
              products: [
                {
                  id: res?._id,
                  name: `${res?.name} ${res?._id}`,
                  //   category: res
                  //     ? `${res.category.name}/${res.sub_category.title.ru}`
                  //     : "",
                  // price: getFinalPrice(product.options && product.options.length === 1 ? {
                  //   price: product.options[0].price,
                  //   ...product.price_data,
                  // } : product.price_data),
                  //   variant: res.sizes ? res.sizes[0].name : "Без размера",
                  options: res.options,
                },
              ],
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setPreloaders((prevState) => ({
          ...prevState,
          item: false,
        }));
      });
  }, [item_id]);


  function getPromotedProducts({ category }) {
    mainApi
      .getItems({
        shop_id: SHOP_ID_FOR_API,
        category,
        limit: 5,
      })
      .then((res) => {
        console.log(res.data);
        setPromotedProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          item: false,
        }));
      });
  }

  const [my_swiper, set_my_swiper] = useState({});

  function removeFromCart() {
    const product_to_card = {
      ...product,
      // selected_size: selectedSize,
      options: selectedOptions,
    };
    console.log(product_to_card);
    handleRemoveFromCart(product_to_card, true);
  }
  const inCart = product && cart && cart.find((cart_item) => {
    if (cart_item._id !== product._id) {
      return false; // Skip items with a different ID early
    }

    // If the item has options and selectedOptions are provided, compare them
    if (cart_item.options && cart_item.options.length > 0 && selectedOptions && selectedOptions.length > 0) {
      const comparisonResult = compareOptions(selectedOptions, cart_item.options);
      console.log(comparisonResult, cart_item)
      return comparisonResult.differences.length === 0;
    } else {
      // If there are no options to compare, just check the _id
      return true;
    }
  });
  const navigate = useNavigate();
  function addToCart() {
    if (inCart) {
      navigate(`/${CART_MAIN_LINK}`);
      return;
    }
    const product_to_card = {
      ...product,
      options: selectedOptions,
      // selected_size: selectedSize,
      count: 1,
    };
    console.log(product_to_card);
    handleAddToCart(product_to_card);
  }

  const options = product ? product.options : []

  function handleSelectOption({ option }) {
    setSelectedOptions(prevValue => prevValue.map((item, i) => {
      if (item.name !== option.name) return item
      return {
        ...item,
        value: option.value,
        in_stock: option.in_stock,
        price: option.price,
      }
    }))
  }
  const reversedArray = product && product.categories && product.categories[0] && product.categories[0].parents ? [...product.categories[0].parents].reverse() : [];

  const in_stock = product ? product.options.length === 1 ? selectedOptions[0].in_stock : product.price_data.in_stock : null

  const { setContactPopupOpen: setContactOpen } = useContext(ContactContext);

  function openContactPopup() {
    setContactOpen(true);
  }

  const isContactUsOnly = product && product.parameters && product.parameters.length > 0 ? product.parameters.some(param => param.name === '_contact-us-only' && param.value === true) : false;

  const price = product ? selectedOptions && selectedOptions.length === 1 ? selectedOptions[0].price : product.price_data.price : null

  return (
    <>
      <Helmet>
        <title>{SHOP_NAME} - ...</title>
      </Helmet>
      {preloaders.item ? (
        <div className="product-page__preloader">
          <MiniPreloader />
        </div>
      ) : product ? (
        <div className="product-page">
          <Helmet>
            <title>{SHOP_NAME} - {product.name}</title>
          </Helmet>
          {/* <p>{category_type} {sub_category_type} {id}</p> */}
          <div className="product-page__content">
            <div className="product-page__crumbs">
              {reversedArray ?
                reversedArray.map((category, i) => (
                  <Link
                    to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(
                      reversedArray,
                      i
                    )}`}
                  >
                    <p className="product-page__crumb">
                      {category.name}
                      {i !== reversedArray.length - 1 ? ` ›` : ""}
                    </p>
                  </Link>
                )) : null}
            </div>
            <h3 className="product-page__name">{parseTitleForParts(product?.name)?.title ? parseTitleForParts(product?.name)?.title : null}</h3>
            <h2 className="product-page__name">{parseTitleForParts(product?.name)?.subtitle ? parseTitleForParts(product?.name)?.subtitle : null}</h2>
            <div className="product-page__item">
              <div className="product-page__photos">
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={0}
                  pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                  }}
                  mousewheel={{
                    forceToAxis: true,
                  }}
                  modules={[Mousewheel]}
                  preventInteractionOnTransition={true}
                  className="photo-slider__cards"
                  onInit={(ev) => {
                    set_my_swiper(ev);
                  }}
                >
                  {product.files && product.files.length > 0 ?
                    product.files.map((item, i) => (
                      <SwiperSlide
                        className="photo-slider__card"
                        key={`photo-slider__card${i}`}
                      >
                        <img
                          // onClick={(e) => {
                          //     // e.target?.requestFullscreen()
                          //     setFullScreenSrc(`${MAIN_URL}/get-file/${item.file_path.orig}`)
                          // }}
                          className="photo-slider__card-img"
                          src={item.urls.orig}
                          alt=""
                        ></img>
                      </SwiperSlide>
                    ))
                    :
                    <SwiperSlide
                      className="photo-slider__card"
                    >
                      <img
                        // onClick={(e) => {
                        //     // e.target?.requestFullscreen()
                        //     setFullScreenSrc(`${MAIN_URL}/get-file/${item.file_path.orig}`)
                        // }}
                        className="photo-slider__card-img"
                        src={IMG_PLACEHOLDER_PRODUCT}
                        alt=""
                      ></img>
                    </SwiperSlide>
                  }
                </Swiper>
              </div>
              <div className="product-page__info">
                <h3 className="product-page__description">
                  {product.description}
                </h3>
                {options && options.length > 0 ?
                  <div className="product-page__options">
                    {options.map((option, i) => {
                      const selected = selectedOptions.filter((item) => item.name === option.name)[0]
                      return (
                        <div className="product-page__option">
                          <h3 className="product-page__option-name">{option.name}: <span className="product-page__option-name-selected">{selected.value}</span></h3>
                          <div className="product-page__option-values">
                            {option.values.map((value_obj, i2) => (
                              <button className={`product-page__option-value ${selected.value === value_obj.value ? 'product-page__option-value_selected' : ''}`} type='button' onClick={() => {
                                handleSelectOption({
                                  option: {
                                    name: option.name,
                                    value: value_obj.value,
                                    in_stock: value_obj.in_stock,
                                    price: value_obj.price,
                                  }
                                })
                              }}>
                                {value_obj.value}
                              </button>
                            ))}
                          </div>
                        </div>
                      )
                    })
                    }
                  </div>
                  :
                  null
                }
                {SHOW_AMOUNT_IN_STOCK_IN_GOODS_PAGE ? !isContactUsOnly ? <p className="product-page__in-stock">В наличии: {in_stock} {getCorrectWordForm(in_stock)}</p> : null : null}
                <div className="product-page__buy">
                  {!isContactUsOnly ?
                    <div className="product-page__price">
                      {/* <h3 className="product-page__price-value">
                      {parsePrice(product.price_data.price, "RUB")}
                    </h3> */}
                      {product.price_data.discount && product.price_data.discount > 0 ?
                        <h3 className='product-page__price-discount'>-{product.price_data.discount}%</h3>
                        :
                        null
                      }
                      {product.price_data.discount && product.price_data.discount > 0 ?
                        <h3 className='product-page__price-value '><span className='product-page__price-value-discounted'>{Number(price)}</span> {parsePrice(Number(price) - Number(price) / 100 * Number(product.price_data.discount), 'RUB')}</h3>
                        :
                        <h3 className='product-page__price-value'>{parsePrice(price, 'RUB')}</h3>
                      }
                    </div>
                    : null}
                  {/* <button className='product-page__purchase-btn'>
                                            <h3 className='product-page__purchase-btn-text'>Добавить в корзину</h3>
                                        </button> */}
                  <div
                    className={`product-info__add-to-cart-box ${inCart ? "product-info__add-to-cart-box_in-cart" : ""
                      }`}
                  >
                    {isContactUsOnly ?
                      <button
                        className={`product-page__purchase-btn`}
                        type="button"
                        onClick={openContactPopup}
                      >
                        <h3 className="product-page__purchase-btn-text">
                          {/* {"Связаться с нами"} */}
                          {CONTACT_US_CART_BTN ? CONTACT_US_CART_BTN : "Хочу также"}
                        </h3>
                      </button>
                      :
                      in_stock ?
                        <button
                          className={`product-page__purchase-btn`}
                          type="button"
                          onClick={addToCart}
                        >
                          <h3 className="product-page__purchase-btn-text">
                            {" "}
                            {inCart ? "В корзине ›" : "Добавить в корзину"}
                          </h3>
                        </button>
                        :
                        <div
                          className={`product-page__purchase-btn product-page__purchase-btn_type_not-in-stock`}
                        >
                          <h3 className="product-page__purchase-btn-text">
                            {"Нет в наличии"}
                          </h3>
                        </div>
                    }


                    <motion.button
                      initial={{
                        width: "0",
                        padding: "0",

                        visibility: "hidden",
                      }}
                      animate={{
                        width: inCart ? "33px" : "0px",
                        padding: inCart ? "0 14px 0 10px" : "0",

                        visibility: inCart ? "visible" : "hidden",
                      }}
                      transition={{ duration: 0.2, type: "tween" }}
                      className="product-page__remove-btn"
                      type="button"
                      ref={removeCartRef}
                      onClick={removeFromCart}
                    >
                      <CrossIcon
                        mainClassName={"product-page__remove-icon"}
                        fillClassName={"product-page__remove-icon-fill"}
                      />
                    </motion.button>
                  </div>
                </div>
              </div>
            </div>
     
            {product.parameters && product.parameters.length > 0 ? (
              <>
                <p className="product-page__parameters-title">Характеристики:</p>
                <div className="product-page__parameters">
                  {product.parameters.filter(param => !param.name.startsWith('_')).map((parameter, i) => (
                    <div
                      className="product-page__parameter"
                      key={`${product._id}_parameter_${i}`}
                    >
                      <h4 className="product-page__parameter-title">
                        {parameter.name}
                      </h4>
                      <p className="product-page__parameter-value">
                        {parseParameterValue(parameter)}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="product-page__not-found">
          <NotFoundBlock
            text={
              "Кажется, Вы&nbsp;попытались открыть товар, который пока не&nbsp;поступил к&nbsp;нам в&nbsp;продажу"
            }
            goToMain={true}
          />
        </div>
      )}
    </>
  );
}

export default ProductPage;
