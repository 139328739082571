import React, { useContext } from 'react';
import './Main.css';
import { ConfigContext } from '../../assets/contexts/configContext';
import Promo100 from './Banners/Promo100/Promo100';
import PromoScroll from './Banners/PromoScroll/PromoScroll';
import PromoTextImage, { PromoTextImageReverse } from './Banners/PromoTextImage/PromoTextImage';
import { ShowcaseLine1, ShowcaseLine2, ShowcaseLine3 } from './Showcase/ShowcaseLine/ShowcaseLine';
import { ShowcaseLineImage1, ShowcaseLineImage2, ShowcaseLineImage3 } from './Showcase/ShowcaseLineImage/ShowcaseLineImage';
import { ShowcaseLineHero1, ShowcaseLineHero2, ShowcaseLineHero3 } from './Showcase/ShowcaseLineHero/ShowcaseLineHero';
import ShowcaseScroll from './Showcase/ShowcaseScroll/ShowcaseScroll';
import { Helmet } from 'react-helmet';
import MakeOrder from './Custom/VashKover/MakeOrder/MakeOrder';
import { CategoriesNoText, CategoriesWithText } from './Categories/Categories';
import MainBanner from './Custom/MainBanner/MainBanner';
import BlueBlock from './Custom/BlueBlock/BlueBlock';
import Advantages from './Custom/Advantages/Advantages';

const MAIN_LAYOUT_TYPES = {
    PROMO_BANNER_100: 'promo-banner-100%',
    PROMO_BANNER_SCROLL: 'promo-banner-scroll',
    PROMO_TEXT_IMAGE: 'promo-text-image',
    PROMO_TEXT_IMAGE_REVERSE: 'promo-text-image-reverse',
    SHOWCASE_LINE_1: 'showcase-line-1',
    SHOWCASE_LINE_2: 'showcase-line-2',
    SHOWCASE_LINE_3: 'showcase-line-3',
    SHOWCASE_SCROLL: 'showcase-scroll',
    SHOWCASE_LINE_1_WITH_IMAGE: 'showcase-line-1-with-image',
    SHOWCASE_LINE_2_WITH_IMAGE: 'showcase-line-2-with-image',
    SHOWCASE_LINE_3_WITH_IMAGE: 'showcase-line-3-with-image',
    SHOWCASE_LINE_1_WITH_HERO: 'showcase-line-1-with-hero',
    SHOWCASE_LINE_2_WITH_HERO: 'showcase-line-2-with-hero',
    SHOWCASE_LINE_3_WITH_HERO: 'showcase-line-3-with-hero',

    MAKE_ORDER_VASH_KOVER: 'make-order-kover',
    CUSTOM_BLOCK_0: 'custom-block-0',
    CUSTOM_BLOCK_1: 'custom-block-1',
    CUSTOM_BLOCK_2: 'custom-block-2',
    CUSTOM_BLOCK_3: 'custom-block-3',
    CUSTOM_BLOCK_4: 'custom-block-4',
    CUSTOM_BLOCK_5: 'custom-block-5',
    CUSTOM_BLOCK_6: 'custom-block-6',
    CUSTOM_BLOCK_7: 'custom-block-7',
    CUSTOM_BLOCK_8: 'custom-block-8',
    CUSTOM_BLOCK_9: 'custom-block-9',

    ALL_CATEGORIES_IMG_TEXT: 'all-categories-img-text',
    ALL_CATEGORIES_IMG: 'all-categories-img',

}

const MAIN_LAYOUT_COMPONENTS = {
    [MAIN_LAYOUT_TYPES.PROMO_BANNER_100]: Promo100,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_SCROLL]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1_WITH_IMAGE]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2_WITH_IMAGE]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3_WITH_IMAGE]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1_WITH_HERO]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2_WITH_HERO]: 'null',
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3_WITH_HERO]: 'null',
    [MAIN_LAYOUT_TYPES.PROMO_BANNER_SCROLL]: PromoScroll,
    [MAIN_LAYOUT_TYPES.PROMO_TEXT_IMAGE]: PromoTextImage,
    [MAIN_LAYOUT_TYPES.PROMO_TEXT_IMAGE_REVERSE]: PromoTextImageReverse,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1]: ShowcaseLine1,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2]: ShowcaseLine2,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3]: ShowcaseLine3,
    [MAIN_LAYOUT_TYPES.SHOWCASE_SCROLL]: ShowcaseScroll,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1_WITH_IMAGE]: ShowcaseLineImage1,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2_WITH_IMAGE]: ShowcaseLineImage2,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3_WITH_IMAGE]: ShowcaseLineImage3,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1_WITH_HERO]: ShowcaseLineHero1,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2_WITH_HERO]: ShowcaseLineHero2,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3_WITH_HERO]: ShowcaseLineHero3,

    [MAIN_LAYOUT_TYPES.MAKE_ORDER_VASH_KOVER]: MakeOrder,

    // PLACEHOLDER FOR SUCTOM BLOCKS LAYOUT
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_0]: MainBanner,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_1]: BlueBlock,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_2]: Advantages,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_3]: null,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_4]: null,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_5]: null,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_6]: null,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_7]: null,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_8]: null,
    [MAIN_LAYOUT_TYPES.CUSTOM_BLOCK_9]: null,

    [MAIN_LAYOUT_TYPES.ALL_CATEGORIES_IMG]: CategoriesNoText,
    [MAIN_LAYOUT_TYPES.ALL_CATEGORIES_IMG_TEXT]: CategoriesWithText,
}

function Main() {
    const config = useContext(ConfigContext)
    const { mainLayout, initialConstants } = config
    const { SHOP_NAME } = initialConstants


    return (
        <div className='main'>
            <Helmet>
                <title>{SHOP_NAME}</title>
            </Helmet>
            <div className='main__content'>
                {
                    mainLayout && mainLayout.length > 0 && Array.isArray(mainLayout) ?
                        mainLayout.map((item, i) => {
                            if (!item || !item.type || !MAIN_LAYOUT_COMPONENTS[item.type]) return null
                            return (
                                <React.Fragment key={`main-layout-item_${item._id}-${i}`}>
                                    {React.createElement(MAIN_LAYOUT_COMPONENTS[item.type], { key: i, item, })}
                                </React.Fragment>
                            )
                        })
                        :
                        null
                }
            </div>
        </div>
    );
}

export default Main