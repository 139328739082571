import { useContext, useEffect, useState } from 'react';
import './ProductCard.css';
import { ConfigContext } from '../../../assets/contexts/configContext';
import { Link, useNavigate } from 'react-router-dom';
import { CART_MAIN_LINK, CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT, SHOW_PARAMS_PRODUCT_CARD } from '../../../assets/utils/constants';
import { buildCategoryPath, compareOptions, getMinPrice, parsePrice, parseTitleForParts } from '../../../assets/utils/utils';
import { CartIcon, ToCartBtnIcon } from '../../../assets/icons/icons';
import ImageWithBackground from '../../ImageWithBackground/ImageWithBackground';
import { CartContext } from '../../../assets/contexts/cartContext';
import useWindowSize from '../../../assets/hooks/useWindowSize';

const CARD_LAYOUT_TYPES = {
    TITLE: 'item_name',
    DESCRIPTION: 'description',
    IMAGE: 'image',
    BUTTON: 'price',

}

const PRICE_FORMATS = {
    SEPARATELY: 'separately',
    ON_BUTTON: 'on_button',
}

const BUY_BTN_FORMATS = {
    TEXT: 'text',
    BUTTON: 'button',
}

function ProductCard({
    item, path
}) {
    console.log(item)
    const { width } = useWindowSize()

    const config = useContext(ConfigContext)
    const { cardTokens, initialConstants } = config
    const { CONSTANTS } = initialConstants
    const { ENABLE_PHOTO_AUTOADJUST = true } = CONSTANTS
    const {
        // cards_in_line,
        but_btn_format,
        image_format,
        image_radius,
        layout,
        price_format,
    } = cardTokens
    // Reverse the array and store it
    const reversedArray = [...item.categories[0].parents].reverse();

    // const [bgColor, setBgColor] = useState('transparent');

    // useEffect(() => {
    //     Vibrant.from(src).getPalette()
    //       .then(palette => {
    //         const rgbColor = palette.Vibrant?.getRgb();
    //         if (rgbColor) {
    //           setBgColor(`rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]})`);
    //         }
    //       })
    //       .catch(err => console.error('Error extracting color:', err));
    //   }, [src]);

    const cart_context = useContext(CartContext)
    const {
        cart,
        handleAddToCart,
        handleRemoveFromCart,
        // setCart,
    } = cart_context


    const inCart = item && cart && cart.find((cart_item) => {
        if (cart_item._id !== item._id) {
            return false; // Skip items with a different ID early
        }
        // If the item has options and selectedOptions are provided, compare them
        if (cart_item.options && cart_item.options.length > 0) {
            return false
        } else {
            // If there are no options to compare, just check the _id
            return true;
        }
    });
    const navigate = useNavigate()
    const in_stock = item ? item.options.length === 1 ? true : item.price_data.in_stock : null
    function handleCartClick(e) {
        if (!in_stock) return
        if (!item.options || item.options.length === 0) {
            e.preventDefault(); // Prevent default button action
            e.stopPropagation(); // Stop the event from bubbling up
            if (inCart) {
                navigate(`/${CART_MAIN_LINK}`);
                return;
            }
            console.log(item)
            const product_to_card = {
                ...item,
                options: null,
                // selected_size: selectedSize,
                count: 1,
            };
            console.log(product_to_card);
            handleAddToCart(product_to_card);
        }
    }

    const in_cart_text = inCart ? 'В корзине ›' : 'В корзину'
    const price = item.options && item.options.length === 1 ? getMinPrice(item.options[0].values) : item.price_data.price

    const [params, setParams] = useState([]);

    function ParamsToShow() {

        const filteredParams = item?.parameters ? item?.parameters?.filter(itemParams =>
            SHOW_PARAMS_PRODUCT_CARD?.includes(itemParams.name)
        ) : null

        setParams(filteredParams ? filteredParams : []); // Ensure params are updated correctly
    }

    useEffect(() => {
        ParamsToShow();
    }, [item]);

    return (
        <Link className='product-card' to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(reversedArray, item.categories[0].parents.length - 1)}/${CATALOG_ITEM_LINK}/${item._id}`}>
            {item.price_data.discount && item.price_data.discount > 0 ?
                <h3 className='product-card__price-discount'>-{item.price_data.discount}%</h3>
                :
                null
            }
            {ENABLE_PHOTO_AUTOADJUST ?
                < ImageWithBackground
                    className={`product-card__image product-card__image_format_${image_format}`}
                    src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                    image_format={image_format}
                    key={`${item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}${item._id}`}
                    metadata={item.files && item.files.length > 0 ? item.files[0].metadata : null}
                />
                :
                <img
                    className={`product-card__image product-card__image_format_${image_format}`}
                    src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                    alt=''
                ></img>
            }
            <h3 className={`product-card__title`}>{item.name && item.name.length > 0 ? String(parseTitleForParts(item?.name)?.title) : ` `}</h3>
            <h3 className={`product-card__subtitle`}>{item.name && item.name.length > 0 && parseTitleForParts(item?.name)?.subtitle ? String(parseTitleForParts(item?.name)?.subtitle) : ` `}</h3>
            {/* <p className={`product-card__description`}>{item.description && item.description.length > 0 ? item.description : ` `}</p> */}
            <div className='product-card__params-and-stock-box'>
                {item?.parameters ?
                    <div className='product-card__params-box'>
                        {params.slice(0, 3).map((itemParams, i) => {
                            return (
                                <>
                                    <p className='product-card__params'>
                                        {itemParams?.value}
                                    </p>
                                    {i + 1 < params.length ? '•' : null}
                                </>

                            )
                        })}
                        {/* <p className='product-card__params'>{item?.parameters[0]?.value}</p>
                        {item?.parameters[1] ? '•' : null}
                        <p className='product-card__params'>{item?.parameters[1]?.value}</p>
                        {item?.parameters[2] ? '•' : null}
                        <p className='product-card__params'>{item?.parameters[2]?.value}</p> */}
                    </div>
                    : null}
                {item?.price_data?.in_stock > 0 ?
                    width > 900 ?
                        <div className='product-card__in-stock'>
                            В наличии
                        </div>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <rect x="1" y="1" width="14" height="14" rx="4" stroke="#00BC1E" />
                            <path d="M5 8L7.3398 10.3398C7.42487 10.4249 7.56524 10.4161 7.63909 10.3212L11 6" stroke="#00BC1E" stroke-width="1.2" stroke-linecap="round" />
                        </svg>
                    :
                    width > 900 ?
                        <div className='product-card__in-stock_dont'>
                            Нет в наличии
                        </div>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <rect x="1" y="1" width="14" height="14" rx="4" stroke="#FF002E" />
                            <path d="M5 8H11" stroke="#FF002E" stroke-width="1.2" stroke-linecap="round" />
                        </svg>
                }
            </div>

            <div className='product-card__separator'></div>
            <button className={`product-card__button`} type='button' onClick={(e) => handleCartClick(e)}>
                {item.price_data.discount && item.price_data.discount > 0 ?
                    <h3 className='product-card__button-price product-card__button-price_discount'>{item.options && item.options.length === 1 ? 'от ' : ''}<span className='product-card__button-price-discounted'>{parsePrice(Number(price), "RUB")}</span> {parsePrice(Number(price) - Number(price) / 100 * Number(item.price_data.discount), 'RUB')}</h3>
                    :
                    <h3 className='product-card__button-price'>{item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(price, 'RUB')}</h3>
                }

                {in_stock ?
                    <div className={`product-card__button-to-cart  ${inCart ? "product-card__button_in-cart" : ""}`}>
                        <p className='product-card__button-to-cart-text'>{
                            width > 900 ?
                                in_cart_text
                                :
                                <ToCartBtnIcon
                                    mainClassName={`product-card__button-to-cart__icon`}
                                    strokeClassName={`${inCart ? 'product-card__button-to-cart__icon_green' : ''}`}
                                />
                        }</p>
                    </div>
                    :
                    null
                }
            </button>
        </Link>
    );
}

export default ProductCard