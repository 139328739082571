import { useContext } from 'react';
import './ShowcaseLine.css';
import { ConfigContext } from '../../../../assets/contexts/configContext';
import ProductCard from '../../../Catalog/ProductCard/ProductCard';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { getRandomId } from '../../../../assets/utils/utils';
import { CART_MAIN_LINK, CATALOG_MAIN_LINK } from '../../../../assets/utils/constants';
import { Link } from 'react-router-dom';

export function ShowcaseLine1({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={1}
        />
    );
}


export function ShowcaseLine2({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={2}
        />
    );
}


export function ShowcaseLine3({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={3}
        />
    );
}




function ShowcaseLine({
    item,
    lines_count,
}) {
    const { width } = useWindowSize()
    const config = useContext(ConfigContext)
    const { cardTokens } = config
    const {
        cards_in_line,
    } = cardTokens


    const { title, products } = item.data[0]
    const _id = getRandomId()
    return (
        <div className='showcase-line'>
            {title ? <h2 className='showcase-line__title'>{title}</h2> : null}
            <div className={`showcase-line__items showcase-line__items_cards-in-line-${cards_in_line}`}>
                {products.map((item, i) => (
                    <ProductCard
                        item={item}
                        key={`showcase-line_${_id}products_item_${i}`}
                    />
                ))}

            </div>
            <Link to={CATALOG_MAIN_LINK} className='showcase-line__btn'>Все товары
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.39205 11.2102L5.36932 10.1989L8.92045 6.64773H0V5.17045H8.92045L5.36932 1.625L6.39205 0.607954L11.6932 5.90909L6.39205 11.2102Z" fill="#0D1738" />
                </svg>
            </Link>
        </div>
    );
}

