import './MainBanner.css';
import main from '../../../../assets/images/main-banner.png'
import mainMobile from '../../../../assets/images/main-banner-mobile.png'

function MainBanner() {

    return (
        <>
            <div className='main-banner'>
               <img src={main} alt='' className='main-banner__img'></img>
               <img src={mainMobile} alt='' className='main-banner__img_mobile'></img>
            </div>
            <div className='main-banner__bg'></div>
        </>
    );
}

export default MainBanner