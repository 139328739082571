import "./BlueBlock.css";
import fish from '../../../../assets/images/blue-block.png'
import fishMobile from '../../../../assets/images/blue-block-mobile.png'
import logo from '../../../../assets/images/logo.png'

function BlueBlock({ }) {
    const TEXT = {
        smallTitle: '',
        title: '',
        text: `Наша компания под брендом FISHTECH собирает и продает качественные LiFePO4 аккумуляторы для катеров, яхт, лодок, кемперов, а так же автодомов, систем домашнего бесперебойного питания, систем хранения солнечной и ветровой энергии. 
<br></br>
        Мы используем самые качественные комплектующие и держим доступные цены за счёт высокого объема продаж.`
    }
    return (
        <div className="blue-block">
            <div className="blue-block__box">
                <img src={fish} alt="" className="blue-block__img"></img>
                <div className="blue-block__text-block">
                    <span className="blue-block__title_small">
                        Интернет-магазин
                    </span>
                    <p className="blue-block__title">
                        <img className="blue-block__logo" src={logo} alt=""></img>
                    </p>
                    <div className="blue-block__line"></div>
                    <img src={fishMobile} alt="" className="blue-block__img_mobile"></img>
                    <div className="blue-block__text" dangerouslySetInnerHTML={{ __html: TEXT.text }} />
                </div>
            </div>
        </div>
    );
}

export default BlueBlock;